import Vue from 'vue';
import _get from 'lodash/get';
import { safeDispatcher } from '@/utils/context/context-helper';

const defaultPage = 1;
const paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    guestServicesSetting: {
      partner_id: '',
      consumer_payment_notice: {
        en: '',
        fr: '',
      },
      consumer_purchase_over_limit_notice: {
        en: '',
        fr: '',
      },
      features: {
        print_generate_voucher: false,
        cancel_voucher: true,
      },
    },
    guestServicesSettings: [],
    pageCount: 0,
    pageNumber: defaultPage,
  },
  getters: {
    guestServicesSettings: (state) => state.guestServicesSettings,
    getGuestServicesSettings: (state) => (partner_id) => state.guestServicesSettings.find((s) => s.partner_id === partner_id),
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },
  mutations: {
    guestServicesSettings(state, guestServicesSettings) {
      state.guestServicesSettings = guestServicesSettings;
    },
    guestServicesSetting(state, guestServicesSetting) {
      state.guestServicesSetting.partner_id = guestServicesSetting.partner_id;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },
  actions: {
    async listGuestServicesSettings({ commit }, { page, limit }) {
      const guestServicesSettingsService = Vue.prototype.$services.guestServicesSettings;

      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const params = {
        page: thePage,
        limit: theLimit,
      };
      const guestServicesSettings = await guestServicesSettingsService.listGuestServicesSettings(params);

      commit('guestServicesSettings', guestServicesSettings.items);
      commit('page', thePage);
      commit('pageCount', guestServicesSettings.page_count);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('guestServicesSettings/listGuestServicesSettings', { page });
    },

    async getGuestServicesSettings({ commit }, { partner_id }) {
      const guestServicesSettingsService = Vue.prototype.$services.guestServicesSettings;
      const guestServicesSetting = await guestServicesSettingsService.getGuestServicesSettings(partner_id);

      commit('guestServicesSetting', guestServicesSetting);
    },

    async updateGuestServicesSettings({ dispatch }, { partner_id, ...payload }) {
      const guestServicesSettingsService = Vue.prototype.$services.guestServicesSettings;
      const defaultDispatch = safeDispatcher(dispatch);

      await guestServicesSettingsService.updateGuestServicesSettings(partner_id, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'guest_services_settings.update_action.success' });
      await defaultDispatch('guestServicesSettings/listGuestServicesSettings', {});
    },

    async createGuestServicesSettings({ dispatch }, payload) {
      const guestServicesSettingsService = Vue.prototype.$services.guestServicesSettings;
      const defaultDispatch = safeDispatcher(dispatch);

      await guestServicesSettingsService.createGuestServicesSettings(payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'guest_services_settings.add_action.success' });
      await defaultDispatch('guestServicesSettings/listGuestServicesSettings', {});
    },
  },
};
